<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="650"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">
          {{ Boolean(item) ? "Edit Sort Plan" : "Add New Sort Plan" }}
        </h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <div class="row flex-grow-0 flex-shrink-0">
          <!--          <div-->
          <!--            class="col-12 h-75px"-->
          <!--            v-if="serverData.hasOwnProperty('customers')"-->
          <!--          >-->
          <!--            <v-autocomplete-->
          <!--              v-model="formData.customer_id"-->
          <!--              label="Client"-->
          <!--              item-text="text"-->
          <!--              item-value="index"-->
          <!--              :items="serverData.customers"-->
          <!--              clearable-->
          <!--              outlined-->
          <!--              dense-->
          <!--              :error-messages="customer_idErrors"-->
          <!--              @input="$v.formData.customer_id.$touch()"-->
          <!--              @blur="$v.formData.customer_id.$touch()"-->
          <!--            />-->
          <!--          </div>-->

          <div class="col-12 h-75px">
            <v-text-field
              v-model="formData.name"
              label="Name"
              clearable
              outlined
              dense
              :error-messages="nameErrors"
              @input="$v.formData.name.$touch()"
              @blur="$v.formData.name.$touch()"
            />
          </div>
        </div>

        <!--begin::Actions-->
        <div
          class="my-4 min-h-75px d-flex flex-grow-1 align-end justify-center"
        >
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import { GET_SORT_PLAN_DATA } from "@/core/services/store/wesSortPlan.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "CreatePlan",
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      // customer_id: {
      //   required: requiredIf(function() {
      //     return this.serverData.hasOwnProperty("customers");
      //   }),
      // },
      name: { required },
      // module_id: { required },
      // status: { required },
    },
  },
  data: () => ({
    dialog: false,
    formData: {
      // customer_id: null,
      name: null,
    },
    item: null,
  }),
  methods: {
    toggleModal(item = null) {
      if (this.dialog) {
        this.resetFormData();
      } else {
        this.item = item;
        this.formData = { ...item };
      }
      this.dialog = !this.dialog;
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v?.formData[fieldName]?.$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    async submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$store.commit(SET_PAGE_LOADING, true);

      if (this.item) {
        await ApiService.post(`/wes/wesSortPlan/update`, {
          ...this.item,
          // customer_id: this.formData.customer_id,
          name: this.formData.name,
          chutes: this.item.chutes.map((chute) => ({
            ...chute,
          })),
        })
          .then(() => {
            swalAdded();
            this.toggleModal();
          })
          .finally(() => {
            this.$store.dispatch(GET_SORT_PLAN_DATA).finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
          });
        // }
      } else {
        await ApiService.post(`/wes/wesSortPlan/store`, {
          ...this.formData,
          chutes: [],
        })
          .then(() => {
            swalAdded();
            this.toggleModal();
          })
          .finally(() => {
            this.$store.dispatch(GET_SORT_PLAN_DATA).finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
          });
      }
    },
    resetFormData() {
      this.formData = {
        // customer_id: null,
        name: null,
      };
    },
  },
  computed: {
    serverData: function () {
      return this.$store.getters.getWESSORTPLANServerData;
    },
    nameErrors: function () {
      return this.handleFormValidation("name");
    },
    // customer_idErrors: function() {
    //   return this.handleFormValidation("customer_id");
    // },
  },
};
</script>
